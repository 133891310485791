.item-selector button.remove {
  display: block;
  position:absolute;
  border: 0;
  background: none;
  top:.5em;
  right: .5em;
  color: #000;
  opacity: .5;
}
.item-selector button.remove:hover {
  opacity:1;
  color: #000 !important;
}

.item-selector .list-group-item.selectable:hover {
  background-color: rgba(0,0,0,.03);
  cursor: pointer;
}