.print-background {
  display: none;
}
a:hover {
  text-decoration: none;
}
.sidebar-toggle:hover .hamburger, .sidebar-toggle:hover .hamburger:after, .sidebar-toggle:hover .hamburger:before {
  background: #487eb0;
}
.sign-in-with-ms {
  width: 215px;
  height:41px;
}
.sign-in-with-ms-short {
  width: 104px;
  height:41px;
}
.risk-matrix th {
  width: 16.66%;
  text-align: center;
}
#root {
  position: relative;
}
.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
a.sidebar-brand {
  position: relative;
  right: 0;
  left: 0;
  background: url(images/ivolv-logo.svg) no-repeat;
  background-size: 12rem;
  min-height: 65px;
  width: 248px;
  position: fixed;
  overflow: hidden;
  margin: 0.85rem 0 1rem 1rem;
  text-indent: -1000rem;
}
.logo-l {
  background: url(images/ivolv-logo-l.svg) no-repeat center;
  text-indent: -1000rem;
  height: 16vh;
  margin-top: 4vh;
}
.logo-l.dark {
  background-image: url(images/ivolv-logo-l-dark.svg);
}
html {
  height: 100%;
}
body {
  font-family: sans-serif;
  height: 100%;
  overflow: hidden;
  background: #2d4459;
  background: linear-gradient(to bottom, #2d4459, #0e2030);
}
body .main {
  background: #d1d6db;
}
body .main.no-sidebar.dark {
  background: linear-gradient(to bottom, #2d4459, #0e2030);
}
body .main.no-sidebar {
  display: flex;
  flex-direction: column;
  height: 100svh;
}
body .main:not(.no-sidebar) {
  position: absolute;
  top: 0;
  bottom: 32px;
  left: 264px;
  right: 0px;
  width: auto;
  overflow: hidden;
}
@media (max-width: 575px) {
  body .main:not(.no-sidebar) {
    bottom: 0;
  }
}
body .wrapper.multitenant .main:not(.no-sidebar),
body .wrapper.multitenant .sidebar {
  top: 42px;
  bottom: 32px;
  position: absolute;
}
body .multitenant.primary .sidebar {
  top: 0px;
  bottom: 32px;
  z-index: 1000;
}
.sidebar-content-wrapper {
  position: absolute;
  top: 111px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

/* Prevent customer selector being obscured by sidebar logo on small screens */
@media (max-width: 680px) {
  body .multitenant.primary .sidebar {
    top: 42px;
  }
  body .wrapper.multitenant .main:not(.no-sidebar),
  body .wrapper.multitenant .sidebar {
    bottom: 0;
  }
}

/* Custom collapse/expand sidebar behavior */
body:not([data-sidebar-position="right"]) .no-sidebar + .main {
  left: 264px;
}
.sidebar.collapsed + .main {
  left: 0px;
}
.sidebar {
  transition: transform 0.35s ease-in-out
}
.sidebar.collapsed {
  transform: translateX(-100%);
}
/* Override auto hide sidebar from adminkit */
/* This is messing with our collapse/expand functionality */
body:not([data-sidebar-position=right]) .sidebar.collapsed {
  margin-left: 0;
}
@media (max-width: 991.98px) {
  body:not([data-sidebar-position=right]) .sidebar {
    margin-left: 0;
  }
}

.main.no-sidebar {
  background: #fff;
}
.main.no-sidebar main.content {
  top: 0;
}
.sidebar {
  position: relative;
}
.main nav.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
footer.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 32px;
  background: #efeff1;
  border-top: 1px solid #c3c2c5;
  padding: 0.4rem 0 0 0;
  font-size: 0.75rem;
  z-index: 3000;
  overflow:hidden;
}
@media (max-width: 575px) {
  footer.footer {
    display: none;
  }
}
.main.dark footer.footer .text-muted {
  color: #a5b2be !important;
}
.main.dark footer.footer {
  border-top: 1px solid #091725;
  background: #2d4459;
}
.main {
  min-height: auto;
}
main.content {
  position: absolute;
  top: 4.26rem;
  bottom: 0px;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 1.375rem;
  border-top: none;
}
@media (max-width: 575px) {
  main.content {
    padding: .5rem;
  }
}
.sidebar-content,
body[data-theme="dark"] .sidebar-content,
.sidebar,
body[data-theme="dark"] .sidebar {
  background-color: #2d4459;
  color: #e9ecef;
}
.sidebar-dropdown .sidebar-link {
  font-size: 100%;
}
.sidebar-link,
a.sidebar-link,
.sidebar-link,
a.sidebar-link:link,
.sidebar-link,
a.sidebar-link:visited,
.sidebar-link,
a.sidebar-link:hover,
button.sidebar-link {
  background-color: transparent;
}
.sidebar-dropdown .sidebar-link,
.sidebar-item.active > a.sidebar-link,
.sidebar-item.active > a.sidebar-link:link,
.sidebar-item.active > a.sidebar-link:hover,
.sidebar-item.active > a.sidebar-link:visited,
.sidebar-item.active > .sidebar-link,
.sidebar button.sidebar-link {
  border: none;
  border-left: 3px solid transparent;
  background: none;
  color: #dcd9df;
  width: 100%;
  text-align: left;
  box-shadow: none;
}
.sidebar button.sidebar-link svg {
  color: #dcd9df;
}
.sidebar-dropdown .sidebar-link::before {
  top:3px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='rgb(220 217 223)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3Cpolyline points='12 5 19 12 12 19'%3E%3C/polyline%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
}
.sidebar-dropdown button.sidebar-link.download::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='rgb(220 217 223)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-download'%3E%3Cpath d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'%3E%3C/path%3E%3Cpolyline points='7 10 12 15 17 10'%3E%3C/polyline%3E%3Cline x1='12' y1='15' x2='12' y2='3'%3E%3C/line%3E%3C/svg%3E");
}
.sidebar-dropdown .sidebar-item .sidebar-link.download:hover:hover:before {
  transform: translateX(0px);
  transform: translateY(4px);
}
.sidebar a.sidebar-link:focus,
.sidebar button.sidebar-link:focus,
.sidebar button.sidebar-link:active:focus {
  text-decoration: underline;
  text-underline-offset: 3px;
  box-shadow: none;
  border-left: 3px solid #5f9ec0 !important;
  color: #dcd9df;
  border-radius: 0;
}
.sidebar .sidebar-link:hover {
  border-left: 3px solid transparent !important;
}
.sidebar-item.navigatable.active > a.sidebar-link,
.sidebar-item.navigatable.active > a.sidebar-link:link,
.sidebar-item.navigatable.active > a.sidebar-link:hover,
.sidebar-item.navigatable.active > a.sidebar-link:visited,
.sidebar-item.navigatable.active > .sidebar-link,
.sidebar button.sidebar-link:active {
  background: #5f9ec04c;
  border-left: 3px solid #5f9ec0;
  color: #dcd9df;
}
.sidebar button.sidebar-dropdown .sidebar-link:focus {
  text-underline-offset: 1px;
}
.sidebar-dropdown .sidebar-item.navigatable.active .sidebar-link,
.sidebar-dropdown > .sidebar-link {
  border-left: 3px solid #bb528a;
}
.sidebar-link,
a.sidebar-link,
.sidebar-link,
a.sidebar-link:link,
a.sidebar-link:visited,
a.sidebar-link i,
a.sidebar-link svg {
  color: #dcd9df;
}
.sidebar-link,
a.sidebar-link:hover,
a.sidebar-link i,
a.sidebar-link:hover svg {
  color: #ece9ef;
}
.navbar button.btn-link {
  color: #2d4459;
}
.navbar button.btn-link:hover {
  color: #3b7cdd;
}
.text-muted fieldset > legend,
.text-muted:not(.footer .text-muted),
tr.text-muted {
  color: #b0b0b0 !important;
}
tr.account-not-supported {
  background-color: #eee;
}
tr.account-not-supported span.badge {
  background-color: #aaa !important;
}
.nav-tabs li {
  margin-right: .2rem;
}
.nav-tabs button.nav-link:not(.active) {
  color: #495057;
  text-decoration: underline;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  background: #efeff1;
}
.nav-tabs .nav-link.active {
  color: #495057;
  background: #fff;
  border: 1px solid;
  border-color: #babdc2 #d6dadf #fff;
}
.nav-pills button.nav-link {
  background-color: #487eb0;
  border-color: #4b6f91;
  color: #fff;
  margin-right: 1rem;
  margin-bottom: .5rem;
}
.nav-pills .nav-link:hover:not(.active),
.nav-pills button.nav-link.active {
  background-color: #386289;
  border-color: #324a60;
}
.nav-pills button.nav-link.active {
  background: #2d4459;
  color: #fff;
}
.nav-pills .nav-link.active {
  border-color: #dee2e6;
}
.main .navbar {
  z-index: 1000;
  border-bottom: 1px solid #c3c2c5;
}
.navbar {
  box-shadow: none;
}
.card {
  border: 1px solid #bbb;
}
.modal-header,
.card-header {
  background: #efeff1;
  padding: 0.75rem 1.25rem 0.75rem;
  margin-bottom: 0;
  color: #5b656d;
}
.card-header .btn.icon,
.card-header .btn.icon:hover {
  background: #efeff1;
  border: none;
  color: #5b656d;
}
.card-footer {
  border-top: 1px solid #efeff1;
}
.card-details-footer {
  border-top: 1px solid #efeff1;
  color: #939ca3;
  padding: 0;
  margin:0;
  position: absolute;
  font-size: 0.8rem;
  bottom: 0;
  right:0;
  left:0;
  height: 55px;
  padding: 0 1.2rem;
  white-space: nowrap;
  overflow: hidden;
}
.card-details-footer button.btn-link {
  color: #939ca3;
  font-size: 0.8rem;
  margin-top: 0 !important;
  padding: 0;
  border: none;
  vertical-align: top;
  text-decoration: underline;
}
.card-details-footer button.btn-link svg {
  display: absolute;
}
.card-details-footer div.links {
  margin-top: .5rem;
  padding-left: 1rem;
}
.card-details-footer div.text {
  position: relative;
  top: .5rem;
}
.card-details-footer span.text code {
  color: #939ca3;
}
.card-details-footer span.text span {
  display:inline-block;
}
.card-body.card-details-footer-margin,
.tab-content:has(.card-details-footer),
.card-body:not(:has(.tab-content)):has(.card-details-footer) {
  margin-bottom: 3rem;
}
.card.bg-warning {
  border: 1px solid #d79e24;
}
.card.bg-warning {
  background-color: #fff1c8 !important;
}
.card.bg-warning .card-header {
  background: #fcb92b;
  color: #4d3900;
}
.modal-header .modal-title,
.card-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #5b656d;
}
.card-header .card-title {
  margin: 0;
}
.modal-backdrop.show {
  opacity: 0.8;
  background-color: #2d4459;
}
div.form-control.readonly,
input.form-control[readonly] {
  background-color: #efeff1;
}
.list-inline.form-control,
div.form-control:not(.rbt-input):not(.form-control-sm) {
  min-height: 2rem;
}
div.form-control:not(.rbt-input) {
  background-color: #efeff1;
}

.risk-matrix.table th,
.risk-matrix.table td:first-child {
  font-weight: normal !important;
  font-size: 0.75rem !important;
}
.table > :not(:first-child) {
  border-top: none;
}
.table > tbody > tr > td {
  vertical-align: text-top;
}
.risk-matrix.table > :not(:last-child) > :last-child > *,
.risk-matrix.table tr:not(:last-child),
.risk-matrix.table tr:not(:last-child) td {
  border: 1px solid #efeff1;
  border-color: #efeff1;
  border-spacing: 0;
}
.table > :not(:last-child) > :last-child > *,
.table:not(.table-borderless) > tbody > tr:not(.expanded):not(:last-child),
.table:not(.table-borderless) > tbody > tr:not(.expanded):not(:last-child) td {
  border-bottom: 1px solid #efeff1;
}
.table tr.odd {
  background: #efeff1;
}
.dropdown-item.ivolv-rbt-overflow-item {
  border-top: 1px solid #efeff1;
  text-align: left;
  font-size: 0.8rem;
}
a.dropdown-item:hover {
  background: #efeff1;
  color: #495057;
}
.list-group .list-group-item button {
  padding: 0;
  margin: 0;
}
.list-group .list-group-item {
  padding: 0.5rem 0.85rem;
}
.list-group-item span {
  padding-right:16px;
}
.list-group .list-group-item.active,
.list-group .list-group-item.active:focus,
a.dropdown-item.active {
  background: #efeff1;
  color: #495057;
  border-color: rgba(0, 0, 0, 0.125);
}
span.dropdown-item:hover {
  background: none;
}
.rbt-aux button {
  margin-top: 0 !important;
}
fieldset {
  padding: 0 1rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-top: 0.5rem;
}
fieldset > legend {
  font-size: 0.9rem;
  font-weight: bold;
  float: none;
  width: auto;
  padding: 0 1rem;
  color: #5b656d;
}
.default-form-layout .form-label {
  margin-bottom: 0.25rem;
}
.default-form-layout .row > div {
  margin-bottom: 0.75rem;
}
div.form-control {
  overflow: auto;
}
.tooltip-inner {
  max-width: none;
}
tr.loading {
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes loading {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #fff6d6;
  }
  100% {
    background-color: #fff;
  }
}
h5.card-title span.tooltip-trigger {
  margin-left: 1rem;
}

.card-close-button {
  position: absolute;
  right: 0.28rem;
  top: 0.28rem;
  margin: 0;
}

.risk-closed,
.vuln-mitigated-bg {
  background-color: #186 !important;
}
.risk-unknown,
.vuln-unknown-bg {
  background-color: #aaa !important;
}
.risk-open,
.vuln-open-bg {
  background: linear-gradient(110deg, #d34, #d34 68%, transparent 68%);
}
.risk-matrix.table tr td.severity-low {
  border: #fff 1px solid;
}
.alert-light.severity {
  background: transparent !important;
  border: 1px solid;
  border-color: #d6dadf;
}
.severity-low {
  background-color: #2c8;
}
.severity-low-text {
  color: #fff;
}
.severity-very-low-lighter {
  background-color: #e2f4ee;
/*  color: #469881;*/
}
.severity-very-low-lighter.alert,
.severity-very-low-lighter.alert-primary,
.severity-very-low-lighter-text,
.severity-very-low-lighter-text *,
.severity-very-low-lighter-text *:link,
.severity-very-low-lighter-text *:hover,
.severity-very-low-lighter-text *:visited {
  color: #107054;
}
.severity-low-lighter {
  background-color: #d2f1e8;
  /*color: #107054;*/
}
.severity-low-lighter.alert,
.severity-low-lighter.alert-primary,
.severity-low-lighter-text,
.severity-low-lighter-text *,
.severity-low-lighter-text *:link,
.severity-low-lighter-text *:hover,
.severity-low-lighter-text *:visited {
  color: #107054;
}
.risk-matrix.table tr td.severity-medium {
  border: #fff 1px solid;
}
.severity-medium {
  background-color: #fb3;
}
.severity-medium-text {
  color: #fff;
}
.severity-medium-lighter {
  background-color: #fef1d5;
  /*color: #976f1a;*/
}
.severity-medium-lighter.alert,
.severity-medium-lighter.alert-primary,
.severity-medium-lighter-text,
.severity-medium-lighter-text *,
.severity-medium-lighter-text *:link,
.severity-medium-lighter-text *:hover,
.severity-medium-lighter-text *:visited {
  color: #876417;
}
.risk-matrix.table tr td.severity-high {
  border: #fff 1px solid;
}
.risk-matrix .highlight .risk-matrix-cell-background {
  box-shadow: inset 0px 0px 100px rgba(0, 0, 0, 0.4);
  opacity: 1 !important;
}
.risk-open-full {
  background-color: #d34;
  color: #fff;
}
.severity-high {
  background-color: #d34;
}
.severity-high-text {
  color: #fff;
}
.severity-high-lighter {
  background-color: #f8d7da;
  color: #65151c;
}
.severity-high-lighter.alert,
.severity-high-lighter.alert-primary,
.severity-high-lighter-text,
.severity-high-lighter-text *,
.severity-high-lighter-text *:link,
.severity-high-lighter-text *:hover,
.severity-high-lighter-text *:visited {
  color: #65151c;
}
.severity-very-high-lighter {
  background-color: #f5b8bd;
  /*color: #320307;*/
}
.severity-very-high-lighter.alert,
.severity-very-high-lighter.alert-primary,
.severity-very-high-lighter-text,
.severity-very-high-lighter-text *,
.severity-very-high-lighter-text *:link,
.severity-very-high-lighter-text *:hover,
.severity-very-high-lighter-text *:visited {
  color: #320307;
}

.card-header {
  font-weight: 600;
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 1px, rgba(0, 0, 0, 0.6) 0px 0px 2px;
}

.id-badge > span,
span.id-badge {
  font-size: 0.8rem;
  min-width: 1.2rem;
  text-align: left;
  padding-left: 8px;
  padding-right: 2rem;
}

td > p:last-child,
div.form-control > p:last-child {
  margin-bottom: 0;
}

.risk-matrix-cell-background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.risk-matrix .table-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.75rem;
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.risk-matrix .table-cell button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  border: none;
  color: inherit;
  text-shadow: inherit;
  font-weight: inherit;
  min-width: 100%;
  min-height: 100%;
}

.card.fill-content {
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  border: 0;
  margin-bottom: 0;
  border-radius: 0;
}
.card.fill-content .card-body {
  padding: 0 16px 0 16px;
}
@media (max-width: 575px) {
.card.fill-content .card-body {
  padding: 0 8px 0 8px;
}
}
.card.fill-content > .card-header {
  display: none;
}

.card.fill-content .paginator {
  position: absolute;
  bottom: 0;
  right: 16px;
  left: 16px;
  bottom: 0;
  margin-left: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: auto;
  border-top: 1px solid #ddd;
  background-color: #fff;
}
.paginator-top {
  padding-bottom: .75rem;
  border-bottom: 1px solid #efeff1;
}
.card.fill-content .paginator-top {
  display: none;
}
/*
.paginator .has-more {
  background-color: #fff2da;
  padding: 5px;
  border-radius: 5px;
}
*/
.card.fill-content .pagination-row-top {
  display: none;
}
.card.fill-content .table {
  margin-bottom: 3rem;
  border-collapse: separate;
  border-spacing: 0;
}
.card.fill-content .table thead tr {
  padding-top: 32px;
}
.card.fill-content .table thead tr,
.card.fill-content .table thead td,
.card.fill-content .table thead th {
  border: none;
}
.card.fill-content .table thead tr th {
  border-bottom: 1px solid #dee2e6;
}
.card.fill-content .table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
}
.card.fill-content .table thead tr {
  border-bottom: #aaa;
}

.card-body input.rbt-input:not([value=""]),
.react-table .filter-row > th > div > input:not([value=""]),
.react-table input.global-filter:not([value=""]),
.react-table .filter-row > th > div > .has-selection button.form-select,
div.form-control.has-selection,
select.has-selection,
.has-selection .form-select,
input.has-selection,
input:focus.has-selection,
fieldset.has-selection {
  background-color: #fff2da;
}
.form-check.dirty {
  background-color: #fff2da;
}
.react-table .paginator input[type="number"]:not([value="1"]) {
  background-color: #fff2da;
}
/*
button.has-selection {
  box-shadow:inset 0px 0px 0px 30px #fff2da;
  border-width: 2px;
  color: #333;
}
button.btn.btn-secondary.has-selection:hover {
  color: #333;
  box-shadow:inset 0px 0px 0px 30px #daccb2;
}
*/

/*
Override typeahead menu padding to suppress popper warning

Popper: CSS "margin" styles cannot be used to apply padding between the popper and its reference
element or boundary. To replicate margin, use the `offset` modifier, as well as the `padding` option
in the `preventOverflow` and `flip` modifiers.
 */
.rbt-menu {
  margin-bottom: 0px !important;
}

.form-label {
  font-weight: bold;
}
input[type="checkbox"] + label.form-label {
  font-weight: normal;
}

.modal-header .btn-close {
  background-color: #dc3545;
  border-color: #dc3545;
  opacity: 1;
  color: #fff;
  padding: 4px 12px 10px 10px;
  transition: background-color 0.5s ease;
}
.modal-header .btn-close:hover {
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.modal-header .btn-close::before {
  content: "×";
  font-size: 1.7rem;
  line-height: 1rem;
}

input[type="file"]:invalid {
  padding-bottom: 0.2rem;
}
input:invalid,
textarea:invalid {
  border-bottom: 1px solid red;
}
textarea:focus:invalid,
input:focus:invalid {
  border: 1px solid #bb2d3b;
  box-shadow: #dc3545 0px 0px 4px;
}
code,
pre code {
  color: #43a;
  font-size: 0.8rem;
}
.rendered-markdown code,
.rendered-markdown pre code {
  word-break: break-word;
}

.rendered-markdown *:last-child {
  margin-bottom: 0 !important;
}
.rendered-markdown table {
  margin-bottom: 1.5rem !important;
}
.rendered-markdown h1,
.rendered-markdown h2,
.rendered-markdown h3,
.rendered-markdown h4,
.rendered-markdown h5,
.rendered-markdown h6 {
  font-size: 1rem;
  font-weight: bold;
}
.rendered-markdown h1 {
  font-size: 1.2rem;
}
.tab-content .tab-pane .rendered-markdown + .react-table {
  margin-top: 1rem;
}

.card-header {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 42px;
  min-height: 42px;
}

.card-header:has(.card-close-button) {
  padding-right: 70px;
}

.vuln-status-col span {
  min-width: 4.8rem;
  text-align: left;
  padding-left: 0.5rem;
  margin-right: 0.8rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #495057;
}

.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5,
.alert h6 {
  color: inherit;
}
.alert {
  overflow: auto;
}
.alert-info {
  background-color: #dbeaf2;
}

blockquote {
  margin-left: 0.3rem;
  padding-left: 0.8rem;
  border-left: 2px solid #aaa !important;
}

.btn-warning,
.btn-warning:hover:not(:disabled):not(.disabled),
.btn-warning:focus {
  color: #440;
}
.btn-warning:hover:not(:disabled):not(.disabled) {
  background-color: #ffcb5c;
}

.tooltip-sm {
  max-width: 20rem;
}

.card-body-header .right-icon,
.card-header .right-icon {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  padding: 0;
}

.table thead th.em,
.table tbody td.em,
.table tbody tr.em td {
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0 !important;
}
.table tbody td {
  word-break: break-word;
}

.active-customer-bar {
  display: none;
  white-space: nowrap;
}
.multitenant .active-customer-bar {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 42px;
  background-color: #fcb92c;
  border-bottom: 1px solid #d19617;
  padding: 0.25rem 0.5rem 0 0.5rem;
  color: #352502;
  z-index: 1000;
}
.multitenant .active-customer-bar .btn-secondary {
  background: #3c6179;
  border: 1px solid #3c6179;
}
.multitenant .active-customer-bar .btn-secondary:hover {
  background: #2d4459;
}
.multitenant.primary .active-customer-bar {
  background-color: #2d4459;
  border-bottom: none;
  color: #f0f0f0;
}

.diagonal-separator {
  position:relative;
}
.diagonal-separator::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 49%, #eee 49%, #eee 51%, transparent 51%);
  background-repeat: no-repeat;
}


.highlight-card-header {
  padding: 0.65rem 2.5rem 0.75rem 1.25rem;
  height: 42px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #efeff1;
  color: #5b656d;
  border-radius: 5px;
  background-color: #fff2da;
  border: 1px solid #86724d;
}
.highlight-card-header .card-body {
  padding: 0;
  margin: 0;
}
.highlight-card-header .card-body .value {
  font-weight: bold;
  padding-left: .2rem;
}

.ancestor-disabled,
.ancestor-disabled .form-check {
  display: block;
}

.btn {
  transition: opacity .15s ease-in-out;
}
thead .btn:disabled {
  opacity: 1;
}
.btn-secondary:disabled {
  background-color: #ccc;
  border-color: #bbb;
}
body .main.no-sidebar .card {
  display: flex;
  flex-grow: 0;
  margin: 1rem auto 3rem auto;
  flex-direction: column;
  justify-content: center;
}
body .main.no-sidebar .logo-l {
  margin-bottom: 1rem;
}
body .main.no-sidebar .card.fill {
  overflow: hidden;
  flex-grow: 1;
}
body .main.no-sidebar .logo-l,
body .main.no-sidebar .card.fill .card-button-footer {
  flex-shrink: 0;
}
body .main.no-sidebar .card.fill .card-button-footer {
  padding: 1rem;
}
body .main.no-sidebar .card.fill .card-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}
.card.transparent {
  background-color: transparent;
  border: transparent;
}
.card.card-xl {
  max-width: 100vw;
}
.card.card-lg {
  max-width: 50rem;
}
.card.card-md {
  max-width: 40rem;
}
.card.card-sm {
  max-width: 25rem;
}

@media(max-width:768px) {
  .logo-l {
    margin-top: 2vh;
  }
  .card.transparent .card-body {
    padding: 0;
  }
  body .main.no-sidebar .card.fill.card-xl,
  body .main.no-sidebar .card.fill.card-lg,
  body .main.no-sidebar .card.fill.card-md {
    margin-bottom: 32px;
    max-width: 100vw;
    width: auto;
  }
  body .main.no-sidebar .card.fill {
    border-radius: 0;
  }
}

@media(max-width:575px) {
  body .main.no-sidebar .card.fill.card-md,
  body .main.no-sidebar .card.fill.card-sm {
    margin-bottom: 0px;
    max-width: 100vw;
    width: auto;
  }
  .footer .product-name {
    display:none;
  }
}

.btn {
  transition: background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    opacity .15s ease-in-out;
}
.btn-primary:disabled,
.btn-primary {
  background-color: #487eb0;
  border-color: #4b6f91;
}
.btn-primary:disabled {
  background-color: #487eb080;
  border-color: #4b6f9140;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #386289;
  border-color: #324a60;
}

a {
  text-decoration:  underline;
}
a.single-line {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vulnerability-details .risk-matrix,
  .vulnerabilities-table .details-row .risk-matrix-container {
  float: right;
  width: 50%;
  margin-left: 1rem;
}
@media (max-width: 960px) {
  .vulnerability-details .risk-matrix,
  .vulnerabilities-table .details-row .risk-matrix-container {
      width: 100%;
      margin-left: 0;
      float: none;
  }
}

.vulnerability-summary-tile .col {
  height: 4.5rem;
  margin-bottom: 1rem;
  padding: 0.25rem;
}

.vulnerability-summary-tile .severity {
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem;
  color: #fff;
}
.vulnerability-summary-tile .severity h3 {
  font-size: 1rem;
}
.vulnerability-summary-tile .severity div {
  font-size: 1.3rem;
}

input.form-control::placeholder {
  color: #bbb;
}

.vulnerability-topic-table h3 {
  font-size: 1.15rem;
}
.vulnerability-topic-table {
  margin-bottom: 1.5rem;
}

.risk-matrix.table.table>:not(caption)>*>* {
  padding: .75rem .5rem;
}
.report .vulnerability-score-tile {
  padding: 2rem;
}
.report .vulnerability-score-tile a.btn {
  display: none;
}
.report .apexcharts-legend {
  overflow: hidden;
}
.alert-secondary {
  background-color: #efeff1;
}
.assignee-bar,
.assignee-bar .form-control {
  font-size: .75rem;
}
.assignee-bar input.form-control {
  padding: .1rem .3rem;
}
.assignee-bar input.form-control::placeholder {
  color: #41464b ;
}
.assignee-bar input.form-control:focus::placeholder {
  color: #c4c8cc;
}
.assignee-bar .rbt {
  width: auto;
  display:inline-block;
}
.assignee-bar .alert {
  overflow: visible;
}
.assignee-bar .assignee {
  padding: .2rem 0rem;
  display: inline-block;
}
.assignee-bar .assign-buttons {
  padding: 0;
  margin: 0;
  position:relative;
  top: 3px;
}

.entity-comments textarea.form-control.growable {
  height: 32px;
}
.entity-comments div.form-control.preview,
.entity-comments textarea.form-control.growable:focus,
.entity-comments textarea.form-control.growable.dirty {
  height: 100px;
}
.entity-comments textarea.form-control.growable {
  transition: height 0.1s ease-in-out
}
.entity-comments .card-body > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.entity-comments .card-body > ul > li {
  margin-bottom: 1rem;
}
.entity-comments ul li:last-of-type {
  margin-bottom: 0;
}
.entity-comments .byline .author {
  font-weight: bold;
}
.entity-comments .byline svg {
  margin-right: .5rem;
  width: 18px;
}
.rendered-markdown div.alert {
  max-width: 50rem;
}
.code-box {
  position: relative;
  width: 100%;
  background-color: #eee;
  display:inline-block;
  margin: 0 0 1rem 0;
  padding: 0;
  border: 2px dotted #aaa;
  max-width: 50rem;
}
.tab-content .code-box {
  background-color: #fff;
  border: 2px dotted #aaa;
  border-top: none;
}
.code-box.w-100 {
  max-width: 100%;
}
pre.pre-wrap,
.code-box pre {
  white-space: pre-wrap;
}
.code-box button {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  border: 0;
  font-size: 1.2rem;
  line-height: 1rem;
  font-weight: bold;
  text-indent: -99999px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-copy'%3E%3Crect x='9' y='9' width='13' height='13' rx='2' ry='2'%3E%3C/rect%3E%3Cpath d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'%3E%3C/path%3E%3C/svg%3E") !important;
}
.code-box pre>code {
  color: #333;
}
.code-box pre {
  margin: 1rem 1.25rem 1rem 1.25rem !important;
}
.mask-area {
  position: absolute;
  background: #fff;
  opacity: .7;
  margin: auto;
  z-index:1000;
  top:0;
  right:0;
  bottom:0;
  left:0;
}
button.btn.btn-text {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  display:inline;
  text-align: left;
  text-decoration: underline;
}
button.btn.btn-text:disabled {
  text-decoration: none;
}
button.btn.text-bold {
  font-weight: bold;
}
button.btn.btn-secondary.toggle {
  background-color: #b0b0b0;
  border: 1px dashed #888 !important;
}
.small .btn {
  font-size: 1em;
}

.modal-dialog-scrollable {
  height: calc(100% - 5rem);
}
@media (max-width: 575px) {
  .modal-dialog-scrollable {
    height: calc(100% - 1rem);
  }
}

.criticality-bar .item {
  width: 20%;
  padding-right: .5rem;
}
.criticality-bar .item:last-child {
  padding-right: 0;
}
.criticality-bar .item>button {
  border-radius: 5px !important;
  text-align: center;
  padding: 0.5rem;
  color: #fff;
  position: relative;
  border:none;
  width:100%;
  border: 1px;
  background: none;
}
.criticality-bar .item.highlight * {
  color: #333;
}
/* .criticality-bar .item.highlight .background {
  box-shadow: inset 0px 0px 100px rgba(0, 0, 0, 0.4);
  opacity: 1 !important;
} */
.criticality-bar .item .background {
  border-radius: 5px !important;
}
.criticality-bar .item.highlight .background {
  border-bottom: 5px solid;
}
.criticality-bar .item.highlight .severity-very-high-lighter.background,
.criticality-bar .item.highlight .severity-high-lighter.background {
  border-color: #d34;
}
.criticality-bar .item.highlight .severity-medium-lighter.background {
  border-color: #fb3;
}
.criticality-bar .item.highlight .severity-very-low-lighter.background,
.criticality-bar .item.highlight .severity-low-lighter.background {
  border-color: #2c8;
}
.criticality-bar .item .background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.criticality-bar .item h3 {
  font-size: 1rem;
}
.criticality-bar .item div {
  font-size: 1.3rem;
}

.table td.row-click button {
  transition: background-color 0.20s ease-in-out;
  transition: border-color 0.20s ease-in-out;
}
tr.hover td.row-click button {
  background: #487eb0;
  border-color: #4c6f91;
}
.table tr {
  transition: background-color 0.20s ease-in-out;
}
.table tr.odd.hover {
  background-color: #d4e2eb;
}
.table tr.even.hover {
  background-color: #deeaf1;
}
.alert.alert-warning code {
  color: #976f1a;
}
code button.btn {
  font-size: .8rem;
  vertical-align: top;
}
.text-muted.form-check>input[type=checkbox] {

}
.check-muted.form-check>input[type=checkbox]:checked {
  background-color: #9ebbe8;
  border-color: #9ebbe8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}
/*.text-muted.form-check>input[type=checkbox] {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}*/