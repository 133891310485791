.error {
    color: #DC3545
}

.no-bottom-margin {
    margin-bottom: 0;
}

.fixed-height-card {
    min-height: 410px;
    max-height: 410px;
}

.check-dropdown-toggle-text {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
}

.check-dropdown-menu {
  min-width: 50px;
  max-height: 327px;
  overflow-y: auto;
  padding: 0;
  inset: 28px auto auto auto !important;
}

.check-dropdown-item {
  padding-left: 8px;
  padding-right: 8px;
}

.check-dropdown-item-search {
  width: 80px;
}

.check-dropdown-checkbox {
  padding-top: 2px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: start;
}

.hide-sidebar {
  margin-left: -264px;
}

.max-width-300 {
  max-width: 300px;
}

.risk-matrix-element {
  cursor: pointer;
  padding: 0 !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.admin-select {
  max-width: 250px;
}

.admin-select option {
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
}

.risk-listgroupitem {
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-select optgroup {
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-100 {
  width: 100px;
}

.width-130 {
  width: 130px;
}

.width-150 {
  width: 150px;
}

.table-sticky-header {
  border-top: none !important;
}

.table-sticky-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: none;
}

.table-sticky-header th::after {
  position: absolute;
  content: '';
  bottom: -2px;
  left: -1px;
  width: calc(100% + 2px);
  height: 2px;;
  background-color: #000000;
}

.cve-category-{
  background-color: #fff;
  color: #495057;
}

.cve-category-none {
  background-color: darkgreen;
  color: #fff;
}

.cve-category-low {
  background-color: lightgreen;
  color: #000;
}

.cve-category-medium {
  background-color: yellow;
  color: #000;
}

.cve-category-high {
  background-color: orange;
  color: #000;
}

.cve-category-critical {
  background-color: red;
  color: #fff;
}

.cve-table-row-selected {
  background: rgba(0, 0, 0, 0.075);
}

.azure-group-flow {
  min-width: 500px;
  width: 100%;
  height: 500px;
}

.azure-node-div {
  cursor: default;
  height: 60px;
}

.azure-node-handle {
  cursor: default;
  visibility: hidden;
}

.azure-node-fixed-text {
  position: absolute;
  bottom: -25px;
  display: flex;
  justify-content: center;
  background: #fff;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.sidebar-content-overflow {
  overflow-y: auto;
}

.tablerow-hover-color {
  background: rgba(0,0,0,.038);
}

.form-border-radius {
 border-radius: .2rem;
}

.customer-users-listgroup td {
  width: 50%;
}

.cust-risk-cat-form {
  max-width: 350px;
}

.cust-risk-cat-delete-tooltip {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: not-allowed;
}

.text-form-switch {
  position: relative;
  transform: scale(1.8);
  /* padding-left: 0px; */
  /* margin-left: 35px; */
}

.text-form-switch input::after {
  content: 'OFF';
  position: absolute;
  left: 12.5px;
  top: 6px;
  font-size: 6px;
  font-weight: 500;
  transition: all 0.05s ease-in-out;
  margin: 0px;
  padding: 0px;
}

.text-form-switch input:checked::after {
  content: 'ON';
  left: 4px;
  top: 6px;
  color: #fff;
}


.split-m365 {
  width: 45px;
  text-align: start;
}

.info-white {
  background: linear-gradient(110deg, #17a2b8 60%, #eee 63%);
}

.info-red {
  background: linear-gradient(110deg, #17a2b8 60%, #dc3545 63%);
}

.info-yellow {
  background: linear-gradient(110deg, #17a2b8 60%, #fcb92c 63%);
}

.info-green {
  background: linear-gradient(110deg, #17a2b8 60%, #1cbb8c 63%);
}

.danger-white {
  background: linear-gradient(110deg, #dc3545 60%,#eee 63%);
}

.danger-red {
  background: linear-gradient(110deg, #dc3545 60%, #dc3545 63%);
}

.danger-yellow {
  background: linear-gradient(110deg, #dc3545 63%, #fcb92c 66%);
}

.danger-green {
  background: linear-gradient(110deg, #dc3545 63%, #1cbb8c 66%);
}

.warning-white {
  background: linear-gradient(110deg, #fcb92c 63%, #eee 66%);
}

.warning-red {
  background: linear-gradient(110deg, #fcb92c 63%, #dc3545 66%);
}

.warning-yellow {
  background: linear-gradient(110deg, #fcb92c 63%, #fcb92c 66%);
}

.warning-green {
  background: linear-gradient(110deg, #fcb92c 63%, #1cbb8c 66%);
}

.white-red {
  background: linear-gradient(110deg, #eee 63%, #dc3545 66%);
}

.white-yellow {
  background: linear-gradient(110deg, #eee 63%, #fcb92c 66%);
}

.white-green {
  background: linear-gradient(110deg, #eee 63%, #1cbb8c 66%);
}

.App-link {
  z-index: 10000;
}

.cve-table-container {
  overflow-y: auto;
  min-height: 100px;
  max-height: 400px;
}

.text-hr {
  border-bottom: 2px solid grey;
  padding-bottom: 4px;
}

.wrapper-class {
  /* padding: 1rem;
  border: 1px solid #ccc; */
}
.editor-class {
  /* background-color:lightgray; */
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.p-overwrite p {
  margin-bottom: 0;
}

.hover-class:hover {
  background-color: rgba(0,0,0,.0375);
  cursor: pointer;
  /* background-color: var(--bs-table-hover-bg) */
}

.rbt-close-content {
  display: none;
}