.reorder-item {
    list-style: none;
}

.list-group-item {
    display: flex;
    flex: auto;
}

.icon-wrapper {
    align-self: center;
    margin-left: auto;
    cursor: grab;
}