.navbar.top-bar .user-dropdown .dropdown-menu {
  min-width: 18rem;
}
.navbar.top-bar .search.has-aux>div>input,
.navbar.top-bar .search>div>input {
  padding: .3rem .8rem;
  margin: 0;
}
.navbar.top-bar .search {
  min-width: 16rem;
}
@media only screen and (max-width: 800px) {
  .navbar.top-bar .search {
    min-width: 12rem;
  }
}
@media only screen and (max-width: 575px) {
  .navbar.top-bar .search {
    max-width: 6rem;
    min-width: 6rem;
  }
}
@media only screen and (max-width: 420px) {
  .navbar.top-bar .search {
    display: none;
  }
}
