.rendered-markdown table {
  width: 100%;
}
.rendered-markdown table > thead > tr > th {
  border-bottom: 2px solid #dee2e6;;
}
.rendered-markdown table > tbody > tr > td {
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
}
.rendered-markdown table > tbody > tr:last-child > td {
  border: none;
}
.rendered-markdown table > tbody > tr > td:not(:last-child) {
  padding-right: 20px;
}