.vulnerability-filter-card .form-control-button {
  position: absolute;
  right: .2rem;
  top: .1rem;
  padding:0;
}
.vulnerability-filter-card .card-body-header {
  padding: 0.65rem 2.5rem 0.75rem 1.25rem;
  margin: 0;
  height: 42px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #efeff1;
  color: #5b656d;
  border-radius: 5px;
}
.vulnerability-filter-card .filter-value {
  font-weight: bold;
  padding-left: .2rem;
}
.vulnerability-filter-card > div > span:not(:last-child)::after {
  content: " - "
}
.vulnerability-filter-card select,
.vulnerability-filter-card div.form-control {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 2rem;
}
.vulnerability-filter-card.dirty .card-body-header {
  background-color: #fff2da;
}
.vulnerability-filter-card.dirty.collapsed {
  border: 1px solid #86724d;
}
